#z{
  background-color:black;
  margin-left: 5rem;
  margin-right: 5rem;
  height: 8rem;
  /* width: 95rem; */
  background-image: url(assets/MaskGroup1.png);
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-repeat: no-repeat;
  background-size: 95rem 10rem;
  border-radius: 0.4rem;

}
#logo-img
{
  background-color:#034180;
  margin-left: 5rem;
  margin-right: 5rem;
  height: 10rem;
  width: 95rem;
  background-image: url(assets/MaskGroup1.png);
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-repeat: no-repeat;
  background-size: 95rem 10rem;
  border-radius: 0.4rem;
}
.logo-text{
  padding-bottom: 0rem;

}
.logo-course{
  padding-top: 0rem;
}
.logo-text{
  margin-left: 3rem;
  padding: 1rem;
  font-size: xx-large;
  color: #f5f5f5;
  border: 0px;
  
}
.logo-course
{
  margin-left: 3rem;
  padding: 1rem;
  font-size: xx-large;
  color: #f5f5f5;
  border: 0px;
}
/* designing of top bar in website */

#logo{
  background-image: url(assets/Logo-2.png);
  width: 4rem;
  height: 1.6rem;
  flex-basis: 79%;
  background-size: contain;
  background-repeat: no-repeat;
}

#couses{
  flex-basis: 7%;
  font-weight:1500;
  border: 0px;
  text-decoration:black;
}
#main-bar
{
  display: flex;
  flex-direction: row;
  background-color: rgb(241, 237, 237);
  box-shadow: 0rem 0.1rem 0.3 rem black;
  height: 1.5rem;
  width: full;
  padding: 1rem;
  border: 0px black;
  
}
#cart
{
  background-image: url(assets/shopping-cart.svg);
  flex-basis: 3%;
  height: 1.6rem;
  background-repeat: no-repeat;
}
#wishlist{
  border: 0px;
  flex-basis: 9%;
  margin-left: 3rem;
  font-weight: bolder;
  border :0px
}
#profile{
  background-image: url(assets/noun_profile_2068277.svg);
  height: 1.6rem;
  flex-basis: 3%;
  background-repeat: no-repeat;
}

#search input[type=text] {
  float: right;
  padding: 8px;
  border: black;
  margin-top: 8px;
  margin-right: 0rem;
  font-size: 17px;
  margin-left: 8rem;
}


/* ----------------RIGHT HALF------------------- */

/* for styling of searchArea */
#search{
  flex-basis: 90%;
    width: 100%;
    height:1.5rem;
    box-sizing: border-box;
    border: none;
    background-color:#EFF2FF;
    color: #444f5a;
    margin-left: -0.8rem;
  }
  #search-icon{
    flex-basis: 10%;
    background-image: url(assets/Group\ 35.svg);
    background-color: #FF6738;
    background-repeat: no-repeat;
    background-position: center;
    width: 1rem;
    height: 1.5rem;
    background-size: 1rem 1.5rem;
    
  }
  #sideSearch{
    width:100%;
    height: 34.5rem;
    box-shadow:0rem 0.25rem 0.5rem  #00000029;
  }
  #search-area{
    display: flex;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  /* #search-area{
    margin-top: 2rem;
    margin-left: 1rem;
  } */
  #Cart{
    display: grid;
    text-decoration: none;
    height:39rem;
    grid-template-rows: 4fr 35fr 8fr;
  }
    #Cheading{
      text-align: center;
      box-shadow: 0rem 0.2rem 0rem #00000029;
      opacity: 80%;
      font-weight: 600;
      width: 90%;
      margin-right: 1rem;
      margin-left: 1rem;
      margin-top: 2rem;
      font-weight: bolder;
      font-size: x-large;
    }
    #Cdetails{
    overflow: scroll;
    font-size: small;
    padding:1rem;
    color: darkgray;
    font-style: italic;
    font-weight: bolder;
    }
    #TotalAmount{
      border: 0px;
      grid-auto-rows: 1fr 2fr;
      box-shadow: none;
      display: grid;
    }
    #Ctext{
      font-weight: bolder;
      font-size:x-large;
      box-shadow: 0rem 0.25rem 0.5rem #00000029;
      padding-left: 1rem;
    }
    #total{
      font-size: x-large;
      padding-left: 1rem;
      font-weight: bolder;
      box-shadow: 0rem 0.25rem 0.5rem #00000029;
    }
  
/* for styling the Cart details */
#CartValue{
  display: grid;
  grid-template-rows: 3fr 1fr;
  box-shadow: 0rem 0.1rem 0rem #00000029 ;
  height: 6rem;
  border: 0px;
  text-decoration: none;
}
#Cart-title{

  font-weight: 600;
  flex-direction: row;
  display: flex;
  color: #0f1317;
  font-style: normal;
  border: 0px;

}
#cart-image{
  margin-right: 0.3rem;
  margin-top: 0.4rem;
  margin-bottom: 0.3rem;
  width: 2rem;
  height: 2.2rem;
  background-color: #f2f2f2;
  padding-bottom: 0.3rem;
  padding-top: 0.3rem;
  flex-basis: 20%;

}
#values-name{
  flex-basis:80% ;
}
#cartTotal{
  font-style: normal;
  font-weight: bolder;
  text-align: end;
  margin-right: 1rem;
  color: #0F1317;
  font-size: x-large;
  margin-right: 1rem;
  margin-top: 2rem;
}





/* for styling the main body area */
.MainBody{
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: full;
  height: 45rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  border: 1px solid black;
  margin-left: 5rem;
  margin-right: 5rem;
}
.card-class{
  padding-right: 45px;
}
#BodyMenu{
  display: flex;
  flex-direction: column;
}
#bmenubar{
  display: flex;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0px;
  background-repeat: no-repeat;
}
#btext{
  flex-basis: 100%;
  font-weight:1500;
  text-decoration: black;
  font-weight: bolder;
  font-size: larger;
  
}
.sortArea{
  flex-basis: 100%;
  
  height: 3rem;

}
/* styling of body aea */
.Barea{
  border: 1px solid black;
  height: 7rem;
  margin: 0.2rem;
  width:100%;
  border:1px solid #F4F4F4;
  border-radius: 0rem 0rem 0.4rem 0.4rem;
  box-shadow: 0rem 0.25rem 0.5rem #00000029;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  padding-left: 1rem;

} 

#Bwishlist,#discount,#Badd-cart{
  width: 100%;
  align-self: center;
}
#next-page{
  width: 100%;
  align-self: center;
}
#Bimage{
  padding-top: 2rem;
  margin-right: 0.1rem;
  background-color: #f2f2f2;
  flex-basis:8%;
  margin-right: 0.1rem;
  padding-bottom: 2rem;

}
#Btitle{
  flex-basis: 30%;
  height: contain;
  display: grid;
  grid-template-rows:2fr 1fr ;
}
#Bimg,#Bauthor,#Bcost-price{
  width: 100%;
  align-self: center;
}
#Bname{
  height: 100%;
  font-weight: bold;
}

#Bauthor{
  flex-basis: 18%;
  margin-left: 0.4rem;
  margin-right: 0.1rem;
  height: 2rem;
}
#Bwishlist{
  flex-basis: 3%;
  background-size: contain;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  background-repeat: no-repeat;
  background-image:url ;
  height: 2rem;
  background-image: url(assets/Path28-1.svg);
}
#Bcost-price{
  font-weight: bold;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  
  opacity: 0.7;
  flex-basis: 7.5%;
  height: 2rem;
}
#Bdiscount{
  font-weight: bold;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  
  opacity: 0.7;
  flex-basis: 7.5%;
  height: 2rem;
  align-self: center;

}
#Bcourse{
  height: 100%;
  display: flex;
  flex-direction: row
;
}
#Btag{
  width: 4rem;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  border-radius: 0.2rem;
  text-align: center;
  padding-right: 0.2rem;
  padding-left : 0.2rem;
  font-size: 100%;
  background-color: #ff6738;
  height: 1.2rem;
}
#Badd-cart{
  flex-basis: 15%;
}
#next-page{
  flex-basis: 3%;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image:url(assets/Path20.svg) ;
}
#add-items{
  border: none;
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.1rem;
  cursor: pointer;
  background-color:#ff6738;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  text-align: center;
  border-radius: 0.3rem;
  text-decoration: none;
}

header{
  width: full;
}
.but{
  align-items:center;
  height: 60px;
  width: 100px;
  background-color: chocolate;
}
#b{
  padding-left: 1rem;
  width: 200px;
}